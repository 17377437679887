import { Fragment } from "react"
import React from "react"
import { Disclosure, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { AdvancedFilteringOptions } from "./advanced-filtering-options"
import { IContract } from "../../types/interfaces/IGetAllContractsResponse"
import { ContractStatus } from "../../types/enums/contract-status"
import _ from "lodash"
import { IFilters } from "../../types/interfaces/IFilters"
import { AdvancedFileringPills } from "./advanced-filtering-pills"
import { getResourceStringByid } from "../../utils/resource-string-helper"
import { useAllKontentResourceStringData } from "../../graphql-static/use-resource-strings"

export type advancedFilteringProps = {
  contracts: IContract[]
  activeFilters: IFilters
  updateFilterState: (state: any) => void
}

export const AdvancedFileringMenu = (props: advancedFilteringProps) => {
  const resourceStrings = useAllKontentResourceStringData()

  const addresses = _.sortBy(
    _.uniqBy(
      props.contracts.map(location => {
        return {
          value: location.siteAddress,
          label: location.siteAddress,
        }
      }),
      "value"
    ),
    ["value"]
  )

  const getLocationOptions = _.sortBy(
    _.uniq(_.map(props.contracts, "siteCounty"))
      .filter(option => {
        return option != "" && option != null
      })
      .map(option => {
        return { value: option, label: option }
      }),
    ["value"]
  )

  const getCompanyNames = _.sortBy(
    _.uniq(_.map(props.contracts, "companyName"))
      .filter(option => {
        return option != "" && option != null
      })
      .map(option => {
        return { value: option, label: option }
      }),
    ["value"]
  )

  const getAllStatusOptions = Object.values(ContractStatus)
    .filter(option => {
      return option != ContractStatus.ALL
    })
    .map(option => {
      return {
        value: option,
        label: option,
      }
    })

  const getAssignedUsers = _.sortBy(
    _.uniqBy(
      props.contracts
        .filter(
          contract => contract.assignment != null && contract.assignment != ""
        )
        .map(contract => {
          return { value: contract.assignment, label: contract.assignment }
        }),
      "value"
    ),
    ["value"]
  )

  // This makes me sad - TODO: Return them as an array in the middleware rather than comma separated string
  const getAllEquipmentCategories = _.sortBy(
    _.uniq(
      _.map(
        props.contracts
          .filter(
            contract =>
              contract.equipmentCategory != null &&
              contract.equipmentCategory != ""
          )
          .map(contract => ({
            ...contract,
            equipmentCategory: contract.equipmentCategory
              .split(",")
              .map(item => {
                return item.trim()
              }),
          })),
        "equipmentCategory"
      ).flat()
    ).map(option => {
      return { value: option, label: option }
    }),
    ["value"]
  )

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ")
  }

  return (
    <div className="bg-white border rounded-md mb-5">
      <AdvancedFileringPills
        activeFilters={props.activeFilters}
        updateFilterState={props.updateFilterState}
      />
      <Disclosure>
        {({ open }) => (
          <>
            <div className="justify-between items-center flex py-2 px-4 sm:px-6 lg:px-8">
              <svg
                className="w-auto h-5 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="24.003"
                height="21.803"
                viewBox="0 0 24.003 21.803"
              >
                <path
                  id="Icon_feather-filter"
                  data-name="Icon feather-filter"
                  d="M25,4.5H3l8.8,10.407V22.1l4.4,2.2v-9.4Z"
                  transform="translate(-2 -3.5)"
                  fill="none"
                  stroke="#3a3a3a"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </svg>
              <p className="font-bold">
                {getResourceStringByid(
                  resourceStrings,
                  "cbd13331-dfcd-578b-81f7-3c2336e8fcc1"
                )}
              </p>
              <Disclosure.Button
                className={classNames(
                  open ? "text-gray-900" : "text-gray-500",
                  "group bg-white rounded-md inline-flex items-center ml-auto text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                )}
              >
                <ChevronDownIcon
                  className={classNames(
                    open ? "text-gray-600" : "text-gray-400",
                    "h-9 w-9 group-hover:text-gray-500"
                  )}
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </div>

            <Transition
              unmount={false}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 -translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-1"
            >
              <Disclosure.Panel
                unmount={false}
                className="px-4 sm:px-6 lg:px-8 py-4 transform relative z-10"
              >
                <AdvancedFilteringOptions
                  activeFilters={props.activeFilters}
                  equipmentCategories={getAllEquipmentCategories}
                  addresses={addresses}
                  locations={getLocationOptions}
                  customerNames={getCompanyNames}
                  assignedUsers={getAssignedUsers}
                  status={getAllStatusOptions}
                  updateFilterState={props.updateFilterState}
                />
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  )
}
